'use client';

import { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Cookies from 'js-cookie';
import {API_URL, TG_TOKEN} from "@/helpers/variables";
import {fetchData} from "@/helpers/general-functions";
import {setUserData} from "@/redux/sclices/user-data-slice";

export function useAuth() {
    const dispatch = useDispatch();
    const { loginCheckLoading } = useSelector((state) => state.userData);

    useEffect(() => {
        if (!loginCheckLoading) {
            // Login check already completed, no need to fetch again
            return;
        }

        const token = Cookies.get(TG_TOKEN);

        if (token) {
            (async () => {
                try {
                    const profileResponse = await fetchData(
                        API_URL,
                        'accounts/profile/',
                        'get',
                        {},
                        token
                    );

                    if (profileResponse?.status === 200) {
                        // Update the Redux state to indicate the user is logged in
                        dispatch(
                            setUserData({
                                loginCheckLoading: false,
                                isLoggedIn: true,
                                info: { ...profileResponse?.data },
                            })
                        );
                    } else {
                        // Invalid token; remove it
                        Cookies.remove(TG_TOKEN);
                        dispatch(
                            setUserData({
                                loginCheckLoading: false,
                                isLoggedIn: false,
                                info: {},
                            })
                        );
                    }
                } catch (error) {
                    console.error('Error fetching profile:', error);
                    dispatch(
                        setUserData({
                            loginCheckLoading: false,
                            isLoggedIn: false,
                            info: {},
                        })
                    );
                }
            })();
        } else {
            // No token found; user is not logged in
            dispatch(
                setUserData({
                    loginCheckLoading: false,
                    isLoggedIn: false,
                    info: {},
                })
            );
        }
    }, [dispatch]);
}
