'use client';

import { useAuth } from '@/hooks/use-auth';
import { Providers } from '@/redux/providers';
import { ToastProvider } from '@/components/toast-provider';

export default function ClientApp({ children }) {
    return (
        <Providers>
            {children}
            <ToastProvider />
            <AuthInitializer/>
        </Providers>
    );
}

// Create a new component that initializes authentication
function AuthInitializer({  }) {
    useAuth(); // Now within the Redux Provider
    return <></>;
}
